<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách thiết lập cước phí vận chuyển cho khách hàng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners">
                                    <label>Tất cả chủ hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOwner()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm chủ hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="storeName" @md-selected="getStoreSelected" :md-options="stores" @md-changed="getStores"  @md-opened="getStores">
                                    <label>Tất cả kho hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openStore()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm kho hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th class="left" style="width:15%;">Khách hàng</th>
                                <th class="left" style="width:15%;">Chủ hàng</th>
                                <th class="left" style="width:15%;">Loại hình</th>
                                <th class="left" style="width:15%;">Loại hàng</th>
                                <th class="left" style="width:10%;">Đơn vị</th>
                                <th class="right" style="width:12%;">Cước vận chuyển</th>
                                <th class="right" style="width:10%;">VAT</th>
                                <th class="right" style="width:12%;">Thành tiền</th>
                                <th style="width:10%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="left">{{item.customer.companyAlias}}</td>
                                <td class="left">{{item.owner.companyAlias}}</td>
                                <td class="left">{{getTypeOfTransport(item.typeOfTransport)}}</td>
                                <td class="left">{{getGoodsType(item.goodsTypeId)}}</td>
                                <td class="left">{{item.unit.unitName}}</td>
                                <td class="right">{{ formatNumber(item.unitPrice)}}</td>
                                <td class="right">{{ formatNumber(item.vat)}}%</td>
                                <td class="right">{{formatNumber(item.amount)}}</td>
                                <td class="action">
                                    <md-button @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crudForm ref="crudForm" @close="closeCrud"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="customerType" @close="closeCustomer"/>
        <locationList ref="locationList" @close="closeStore"/>
    </div>
</template>
<script>
    import crudForm from './Crud.vue';
    import settingDebtService from '../../../api/settingDebtService';
    import companyService from '../../../api/companyService';
    import locationService from '../../../api/locationService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import locationList from '../../../components/popup/_LocationList.vue';

    export default {
        components: {
            crudForm,
            customerList,
            ownerList,
            locationList
        },
        metaInfo: {
            title: 'Danh sách thiết lập cước phí vận chuyển cho khách hàng'
        },
        data() {
            return {
                loadding: false,
                customerType: common.companyType.customer,
                search: { pageIndex: 1, pageSize: common.pageSize, customerId: 0, ownerId: 0, storeId: 0, typeOfTransport: 0, goodsTypeId: 0, unitId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                categories: [],
                categoryType: common.categoryType.fee,
                customerName: '',
                customers: [],
                ownerName: '',
                owners: [],
                storeName: '',
                stores: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            closeCrud(action){
                this.refresh();
                if(!action){
                    this.$refs.crudForm.close();
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, customerId: 0, ownerId: 0, storeId: 0, typeOfTransport: 0, goodsTypeId: 0, unitId: 0 };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/setting-debt-customer?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.search.type = 1;
                this.loadding = true;
                settingDebtService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyAlias;
                this.search.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.search.ownerId = val.id;
                this.ownerName = val.companyAlias;
            },

            openOwner(){
                this.$refs.ownerList.open();
            },

            getOwners(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.owners = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //KHO HÀNG
            closeStore(item){
                this.storeName = item.locationName;
                this.search.storeId = item.id;
                this.$refs.locationList.close();
            },

            openStore(){
                this.$refs.locationList.open(0);
            },

            getStoreSelected(val){
                this.search.storeId = val.id;
                this.storeName = val.locationName;
            },
            
            getStores(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, categoryId: 0, companyId: 0, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.stores = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                settingDebtService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(obj){
                this.$refs.crudForm.open(obj.id);
            },

            add(){
                this.$refs.crudForm.open(0);
            }      
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.search.ownerId = 0;
                }
            },
            storeName: function (val) { 
                if(val == ''){
                    this.search.storeId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.ownerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.storeId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.typeOfTransport': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.goodsTypeId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.unitId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
