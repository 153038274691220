<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners" :class="{'md-invalid': submitted && $v.entity.ownerId.$error }">
                                    <label>Chủ hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.ownerId.isSelected">Vui lòng chọn chủ hàng</span>
                                </md-autocomplete>
                                <md-button @click="openOwner()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-4">
                            <div class="form-control">
                                <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods">
                                    <label>Hàng hóa</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hàng hóa</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.typeOfTransport.$error }">
                                    <label>Loại hình</label>
                                    <md-select v-model="entity.typeOfTransport">
                                        <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.typeOfTransport.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.goodsTypeId.$error }">
                                    <label>Loại hàng</label>
                                    <md-select v-model="entity.goodsTypeId">
                                        <md-option v-for="item in goodsTypeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.goodsTypeId.isSelected">Vui lòng chọn hàng</span>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                    <label>Đơn vị</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đợn vị hàng hóa</span>
                                </md-autocomplete>
                                <md-button @click="openUnit()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.unitPrice.$error }">
                                <label for="unitPrice">Cước vận chuyển</label>
                                <number @change="calSum()" v-model="entity.unitPrice" v-bind="currencyF" class="form-control currency"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.unitPrice.required">Vui lòng nhập phí</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.vat.$error }">
                                <label for="vat">VAT</label>
                                <number @change="calSum()" v-model="entity.vat" v-bind="currencyF" class="form-control currency"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.unitPrice.required">Vui lòng nhập vat</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field class="number-control">
                                <div>Thành tiền:</div>
                                <number name="amount" v-model="entity.amount" v-bind="currencyF" disabled></number> 
                            </md-field>
                        </div>
                    </div>
                    <div v-if="entity.typeOfTransport == 1">
                        <div class="row">
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorReceiptContName" @md-selected="getSectorReceiptContSelected" :md-options="sectorReceiptConts" @md-changed="getSectorReceiptConts"  @md-opened="getSectorReceiptConts">
                                        <label>Khu vực nơi lấy cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorReceiptCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                        <label>Nơi nhận/đóng hàng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitDeliveryName" @md-selected="getSectorTransitDeliverySelected" :md-options="sectorTransitDeliverys" @md-changed="getSectorTransitDelivery"  @md-opened="getSectorTransitDelivery">
                                        <label>Khu vực cảng đi/hạ con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitDelivery()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="entity.typeOfTransport == 2">
                        <div class="row">
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitReceiptName" @md-selected="getSectorTransitReceiptSelected" :md-options="sectorTransitReceipts" @md-changed="getSectorTransitReceipt"  @md-opened="getSectorTransitReceipt">
                                        <label>Khu vực cảng đến/nhận con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitReceipt()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                        <label>Nơi trả hàng/trả con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorDeliveryContName" @md-selected="getSectorDeliveryContSelected" :md-options="sectorDeliveryConts" @md-changed="getSectorDeliveryConts"  @md-opened="getSectorDeliveryConts">
                                        <label>Khu vực nơi trả cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorDeliveryCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="entity.typeOfTransport == 3">
                        <div class="row">
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorReceiptContName" @md-selected="getSectorReceiptContSelected" :md-options="sectorReceiptConts" @md-changed="getSectorReceiptConts"  @md-opened="getSectorReceiptConts">
                                        <label>Khu vực nơi lấy cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorReceiptCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                        <label>Nơi nhận/đóng hàng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitDeliveryName" @md-selected="getSectorTransitDeliverySelected" :md-options="sectorTransitDeliverys" @md-changed="getSectorTransitDelivery"  @md-opened="getSectorTransitDelivery">
                                        <label>Khu vực cảng đi/hạ con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitDelivery()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitReceiptName" @md-selected="getSectorTransitReceiptSelected" :md-options="sectorTransitReceipts" @md-changed="getSectorTransitReceipt"  @md-opened="getSectorTransitReceipt">
                                        <label>Khu vực cảng đến/nhận con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitReceipt()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                        <label>Nơi trả hàng/trả con't</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-4 m-4 c-4">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorDeliveryContName" @md-selected="getSectorDeliveryContSelected" :md-options="sectorDeliveryConts" @md-changed="getSectorDeliveryConts"  @md-opened="getSectorDeliveryConts">
                                        <label>Khu vực nơi trả cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorDeliveryCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="entity.typeOfTransport == 4">
                        <div class="row">
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitReceiptName" @md-selected="getSectorTransitReceiptSelected" :md-options="sectorTransitReceipts" @md-changed="getSectorTransitReceipt"  @md-opened="getSectorTransitReceipt">
                                        <label>Khu vực cảng đi</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitReceipt()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorTransitReceiptName" @md-selected="getSectorTransitReceiptSelected" :md-options="sectorTransitReceipts" @md-changed="getSectorTransitReceipt"  @md-opened="getSectorTransitReceipt">
                                        <label>Khu vực cảng đến</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorTransitReceipt()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="entity.typeOfTransport == 5">
                        <div class="row">
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                        <label>Nơi nhận/đóng hàng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                        <label>Nơi trả hàng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="entity.typeOfTransport == 6">
                        <div class="row">
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorReceiptContName" @md-selected="getSectorReceiptContSelected" :md-options="sectorReceiptConts" @md-changed="getSectorReceiptConts"  @md-opened="getSectorReceiptConts">
                                        <label>Khu vực nơi lấy cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorReceiptCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-6">
                                <div class="form-control">
                                    <md-autocomplete v-model="sectorDeliveryContName" @md-selected="getSectorDeliveryContSelected" :md-options="sectorDeliveryConts" @md-changed="getSectorDeliveryConts"  @md-opened="getSectorDeliveryConts">
                                        <label>Khu vực nơi trả cont rỗng</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.sectorName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openSectorDeliveryCont()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <unitList ref="unitList" @close="closeUnit"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="ownerType" @close="closeOwner"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <goodsList ref="goodsList" @close="closeGoods"/>

        <sectorReceiptContList ref="sectorReceiptContList" @close="closeSectorReceiptCont"/>
        <sectorDeliveryContList ref="sectorDeliveryContList" @close="closeSectorDeliveryCont"/>
        <sectorTransitDeliveryList ref="sectorTransitDeliveryList" @close="closeSectorTransitDelivery"/>
        <sectorTransitReceiptList ref="sectorTransitReceiptList" @close="closeSectorTransitReceipt"/>
        <locationReceiptGoods ref="locationReceiptGoods" @close="closePlaceOfReceiptGoods"/>
        <locationDeliveryGoods ref="locationDeliveryGoods" @close="closePlaceOfDeliveryGoods"/>
    </md-dialog>
</template>

<script>
    import locationService from '../../../api/locationService';
    import goodsService from '../../../api/goodsService';
    import sectorService from '../../../api/sectorService';
    import unitService from '../../../api/unitService';
    import companyService from '../../../api/companyService';
    import settingDebtService from '../../../api/settingDebtService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import unitList from '../../../components/popup/_UnitList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import sectorReceiptContList from '../../../components/popup/_SectorList.vue';
    import sectorTransitDeliveryList from '../../../components/popup/_SectorList.vue';
    import sectorTransitReceiptList from '../../../components/popup/_SectorList.vue';
    import sectorDeliveryContList from '../../../components/popup/_SectorList.vue';
    import locationReceiptGoods from '../../../components/popup/_LocationList.vue';
    import locationDeliveryGoods from '../../../components/popup/_LocationList.vue';

    export default ({
        components: {
            unitList,
            customerList,
            ownerList,
            goodsList,
            sectorReceiptContList,
            locationReceiptGoods,
            sectorTransitDeliveryList,
            sectorTransitReceiptList,
            locationDeliveryGoods,
            sectorDeliveryContList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thiết lập cước phí vận chuyển cho khách hàng'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                goodsTypeList: common.goodsTypeList,
                typeList: common.typeOfTransport,
                entity: { id: 0, type: 1, customerId: 0, ownerId: 0, goodsId: null, typeOfTransport: 1, goodsTypeId: 1, unitId: 0, unitPrice: 0, vat: 0, amount: 0 },
                saveAndCreate: false,
                unitName: '',
                units: [],
                currencyF: common.currencyF2,
                customerType: common.companyType.customer,
                ownerType: common.companyType.customer,
                customerName: '',
                customers: [],
                ownerName: '',
                owners: [],
                goodsName: '',
                goods: [],
                sectorTransitDeliveryName: '',
                sectorTransitDeliverys: [],
                sectorTransitReceiptName: '',
                sectorTransitReceipts: [],
                sectorDeliveryContName: '',
                sectorDeliveryConts: [],
                sectorReceiptContName: '',
                sectorReceiptConts: [],
                placeOfReceiptGoodsName: '',
                placeOfReceiptGoodsList: [],
                placeOfDeliveryGoodsName: '',
                placeOfDeliveryGoodsList: []
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSum(){
                this.entity.amount = parseFloat(this.entity.unitPrice) + (parseFloat(this.entity.unitPrice) * (this.entity.vat / 100));
            },

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới thiết lập cước phí vận chuyển cho khách hàng';
                    this.entity = { id: 0, type: 1, customerId: 0, goodsId: null, ownerId: 0, typeOfTransport: 1, goodsTypeId: 1, unitId: 0, unitPrice: 0, vat: 0, amount: 0 };
                    this.unitName = '';
                    this.customerName = '';
                    this.contractName = '';
                    this.ownerName = '';
                    this.sectorReceiptContName = '';
                    this.sectorDeliveryContName = '';
                    this.sectorReceiptGoodsName = '';
                    this.sectorDeliveryGoodsName = '';
                    this.sectorTransitReceiptName = '';
                    this.sectorTransitDeliveryName = '';
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật thiết lập cước phí vận chuyển cho khách hàng';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goods = item;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goods = val;
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open();
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyAlias;
                this.entity.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.entity.ownerId = val.id;
                this.ownerName = val.companyAlias;
            },

            openOwner(){
                this.$refs.ownerList.open();
            },

            getOwners(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.owners = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.entity.unit = item;
                this.unitName = item.unitName;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
                this.entity.unit = val;
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                unitService.getUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                let error = 0;
                if(this.entity.typeOfTransport == 1){
                    if(this.entity.placeOfReceiptGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi nhận/đóng hàng");
                    }
                    else{
                        if(this.entity.sectorReceiptContId == null && this.entity.sectorTransitDeliveryId > 0){
                            error = error + 1;
                            messageBox.showWarning("Vui lòng chọn khu vực lấy con't rỗng và khu vực cảng đi/hạ con't");
                        }
                        else if(this.entity.sectorReceiptContId > 0 && this.entity.sectorTransitDeliveryId == null){
                            error = error + 1;
                            messageBox.showWarning("Vui lòng chọn khu vực lấy con't rỗng và khu vực cảng đi/hạ con't");
                        }
                    }
                }
                else if(this.entity.typeOfTransport == 2){
                    if(this.entity.placeOfDeliveryGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi trả hàng/trả con't");
                    }
                    else{
                        if(this.entity.sectorTransitReceiptId == null && this.entity.sectorDeliveryContId > 0){
                            error = error + 1;
                            messageBox.showWarning("Vui lòng chọn khu vực cảng đến/nhận con't và khu vực trả con't rỗng");
                        }
                        else if(this.entity.sectorTransitReceiptId > 0 && this.entity.sectorDeliveryContId == null){
                            error = error + 1;
                            messageBox.showWarning("Vui lòng chọn khu vực cảng đến/nhận con't và khu vực trả con't rỗng");
                        }
                    }
                }
                else if(this.entity.typeOfTransport == 3){
                    if(this.entity.sectorReceiptContId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực lấy con't rỗng");
                    }
                    if(this.entity.placeOfReceiptGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi nhận/đóng hàng");
                    }
                    if(this.entity.sectorTransitDeliveryId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng khu vực cảng đi/trả con't");
                    }
                    if(this.entity.sectorTransitReceiptId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực cảng đến/nhận con't");
                    }
                    if(this.entity.placeOfDeliveryGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi trả hàng/trả con't");
                    }
                    if(this.entity.sectorDeliveryContId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực trả con't rỗng");
                    }
                }
                else if(this.entity.typeOfTransport == 4){
                    if(this.entity.sectorTransitDeliveryId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng khu vực cảng đi/trả con't");
                    }
                    if(this.entity.sectorTransitReceiptId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực cảng đến/nhận con't");
                    }
                }
                else if(this.entity.typeOfTransport == 5){
                    if(this.entity.placeOfReceiptGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi nhận/đóng hàng");
                    }
                    if(this.entity.placeOfDeliveryGoodsId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn nơi trả hàng/trả con't");
                    }
                }
                else if(this.entity.typeOfTransport == 6){
                    if(this.entity.sectorReceiptContId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực lấy con't rỗng");
                    }
                    if(this.entity.sectorDeliveryContId == null){
                        error = error + 1;
                        messageBox.showWarning("Vui lòng chọn khu vực trả con't rỗng");
                    }
                }
                if (this.$v.$invalid) {
                    return;
                }
                if(error > 0){
                    return;
                }
                this.entity.groupId = parseInt(this.$route.query.groupId);
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                settingDebtService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                settingDebtService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                settingDebtService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.unitName = response.data.unit.unitName;
                        if(this.entity.customerId > 0){
                            this.customerName = response.data.customer.companyAlias;
                        }
                        if(this.entity.contractId > 0){
                            this.contractName = response.data.contract.contractName;
                        }
                        if(this.entity.ownerId > 0){
                            this.ownerName = response.data.owner.companyAlias;
                        }
                        if(this.entity.sectorReceiptContId > 0){
                            this.sectorReceiptContName = response.data.sectorReceiptCont.sectorName;
                        }
                        if(this.entity.sectorDeliveryContId > 0){
                            this.sectorDeliveryContName = response.data.sectorDeliveryCont.sectorName;
                        }
                        if(this.entity.sectorTransitReceiptId > 0){
                            this.sectorTransitReceiptName = response.data.sectorTransitReceipt.sectorName;
                        }
                        if(this.entity.sectorTransitDeliveryId > 0){
                            this.sectorTransitDeliveryName = response.data.sectorTransitDelivery.sectorName;
                        }
                        if(response.data.placeOfReceiptGoods != null){
                            this.placeOfReceiptGoodsName = response.data.placeOfReceiptGoods.locationName;
                        }
                        if(response.data.placeOfDeliveryGoods != null){
                            this.placeOfDeliveryGoodsName = response.data.placeOfDeliveryGoods.locationName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Cảng đi
            closeSectorTransitDelivery(item){
                this.sectorTransitDeliveryName = item.sectorName;
                this.entity.sectorTransitDeliveryId = item.id;
                this.entity.sectorTransitDelivery = item;
                this.$refs.sectorTransitDeliveryList.close();
            },

            openSectorTransitDelivery(){
                this.$refs.sectorTransitDeliveryList.open(0);
            },

            getSectorTransitDeliverySelected(val){
                this.entity.sectorTransitDeliveryId = val.id;
                this.entity.sectorTransitDelivery = val;
                this.sectorTransitDeliveryName = val.sectorName;
            },
            
            getSectorTransitDelivery(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorTransitDeliverys = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Cảng đến
            closeSectorTransitReceipt(item){
                this.sectorTransitReceiptName = item.sectorName;
                this.entity.sectorTransitReceiptId = item.id;
                this.entity.sectorTransitReceipt = item;
                this.$refs.sectorTransitReceiptList.close();
            },

            openSectorTransitReceipt(){
                this.$refs.sectorTransitReceiptList.open(0);
            },

            getSectorTransitReceiptSelected(val){
                this.entity.sectorTransitReceiptId = val.id;
                this.entity.sectorTransitReceipt = val;
                this.sectorTransitReceiptName = val.sectorName;
            },
            
            getSectorTransitReceipt(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorTransitReceipts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfReceiptGoods
            closePlaceOfReceiptGoods(item){
                this.placeOfReceiptGoodsName = item.locationName;
                this.entity.placeOfReceiptGoodsId = item.id;
                this.entity.placeOfReceiptGoods = item;
                this.$refs.locationReceiptGoods.close();
            },

            openPlaceOfReceiptGoods(){
                this.$refs.locationReceiptGoods.open(0);
            },

            getPlaceOfReceiptGoodsSelected(val){
                this.entity.placeOfReceiptGoodsId = val.id;
                this.entity.placeOfReceiptGoods = val;
                this.placeOfReceiptGoodsName = val.locationName;
            },
            
            getPlaceOfReceiptGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfReceiptGoodsList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfDeliveryGoods
            closePlaceOfDeliveryGoods(item){
                this.placeOfDeliveryGoodsName = item.locationName;
                this.entity.placeOfDeliveryGoodsId = item.id;
                this.entity.placeOfDeliveryGoods = item;
                this.$refs.locationDeliveryGoods.close();
            },
            
            openPlaceOfDeliveryGoods(){
                this.$refs.locationDeliveryGoods.open(0);
            },

            getPlaceOfDeliveryGoodsSelected(val){
                this.entity.placeOfDeliveryGoodsId = val.id;
                this.placeOfDeliveryGoodsName = val.locationName;
                 this.entity.placeOfDeliveryGoods = val;
            },

            getPlaceOfDeliveryGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfDeliveryGoodsList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi nhận cont
            closeSectorReceiptCont(item){
                this.sectorReceiptContName = item.sectorName;
                this.entity.sectorReceiptContId = item.id;
                this.entity.sectorReceiptConts = item;
                this.$refs.sectorReceiptContList.close();
            },

            openSectorReceiptCont(){
                this.$refs.sectorReceiptContList.open(0);
            },

            getSectorReceiptContSelected(val){
                this.entity.sectorReceiptContId = val.id;
                this.entity.sectorReceiptConts = val;
                this.sectorReceiptContName = val.sectorName;
            },
            
            getSectorReceiptConts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorReceiptConts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Nơi trả cont
            closeSectorDeliveryCont(item){
                this.sectorDeliveryContName = item.sectorName;
                this.entity.sectorDeliveryContId = item.id;
                this.entity.sectorDeliveryConts = item;
                this.$refs.sectorDeliveryContList.close();
            },

            openSectorDeliveryCont(){
                this.$refs.sectorDeliveryContList.open();
            },

            getSectorDeliveryContSelected(val){
                this.entity.sectorDeliveryContId = val.id;
                this.entity.sectorDeliveryConts = val;
                this.sectorReceiptContName = val.sectorName;
            },
            
            getSectorDeliveryConts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                sectorService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.sectorDeliveryConts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/fee');
            }
        },
        watch: { 
            goodsName: function (val) { 
                if(val == ''){
                    this.entity.goodsId = null;
                }
            },
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = 0;
                }
            },
            customerName: function (val){
                if(val == ''){
                    this.entity.customerId = null;
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.entity.ownerId = null;
                }
            },
            sectorDeliveryContName: function (val) { 
                if(val == ''){
                    this.entity.sectorDeliveryContId = null;
                }
            },
            sectorReceiptContName: function (val) { 
                if(val == ''){
                    this.entity.sectorReceiptContId = null;
                }
            },
            sectorTransitDeliveryName: function (val) { 
                if(val == ''){
                    this.entity.sectorTransitDeliveryId = null;
                }
            },
            sectorTransitReceiptName: function (val) { 
                if(val == ''){
                    this.entity.sectorTransitReceiptId = null;
                }
            },
            placeOfDeliveryGoodsName: function (val) { 
                if(val == ''){
                    this.entity.placeOfDeliveryGoodsId = null;
                }
            },
            placeOfReceiptGoodsName: function (val) { 
                if(val == ''){
                    this.entity.placeOfReceiptGoodsId = null;
                }
            },
        },
        validations: {
            entity: {
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                ownerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                typeOfTransport: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsTypeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitPrice: {
                    required
                },
                vat: {
                    required
                },
            }
        }
     })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 900px;
        height: 500px;
        max-width: 900px;
    }
    .md-scrollbar {
        height: 560px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>